<template>
  <div
    id="bg-color"
    :class="{ taiyoClicked: taiyoClicked }"
  >
    <body
      ref="bodyBg"
      :class="{ taiyoClicked: taiyoClicked }"
    >
      <h1 class="welcome">
        welcome to taiyo
      </h1>
      <h3
        id="enter"
        class="prevent-select taiyo-msg-banner"
      >
        meow . . .
      </h3>
      <img
        id="taiyo-img"
        class="img-fluid prevent-select"
        :class="{ taiyoHoverNonClicked: taiyoHover && !taiyoClicked, taiyoClicked: taiyoClicked }"
        :src="require(`@/assets/images/${taiyoLocation}`)"
        @mouseover="taiyoHover = true"
        @click="chooseTaiyo()"
      >
      <h3 class="mt-3 taiyo-count prevent-select">
        taiyo count: {{ taiyoCount }}
      </h3>
      <h2 class="top-padding">
        <router-link
          id="back-button"
          to="/"
          style="text-decoration: none"
        >
          &#8592; Home
        </router-link>
      </h2>
      <footer class="footer">
        <h5>Taiyo McCloud 2024</h5>
      </footer>
      <div
        id="cursor"
        :style="cursorPosStyle"
      /> 
    </body>
  </div>
</template>

<script>
import { animateBg } from '../utils/radialZoom.js';

export default {
  name: 'Home',
  data() {
    return {
      taiyoPath: '',
      prevTaiyoIndex: -1,
      taiyoIndex: 0,
      taiyoClicked: false,
      taiyoHover: false,
      taiyoCount: 0,
      xPos: 0,
      yPos: 0,
      taiyoZIndex: 100,
    };
  },
  computed: {
    taiyoLocation() {
      return this.taiyoPath;
    },
    cursorPosStyle() {
      return {
        left: this.xPos + "px",
        top: this.yPos + "px"
      }},
      cursorPosStyleleft() {
      return this.xPos
  }},
  created() {
    this.chooseTaiyo(true);
  },
  mounted() {
    animateBg(this.$refs.bodyBg);
    document.addEventListener('mousemove', (e) => {
      this.xPos = e.clientX;
      this.yPos = e.clientY;
    });
  },
  unmounted() {
    animateBg(null, true);
  },
  methods: {
    chooseTaiyo(firstTime = false) {
      do {
        this.taiyoIndex = Math.floor(Math.random() * 14) + 1;
      } while(this.taiyoIndex == this.prevTaiyoIndex);
      console.log(this.taiyoLocation);
      this.prevTaiyoIndex = this.taiyoIndex;
      this.taiyoPath = `taiyo${this.taiyoIndex}.jpg`;
      if(!firstTime){
        this.spawnTaiyoClickEffect();
        this.taiyoCount++;
        this.taiyoClicked = true;
        setTimeout(()=> this.taiyoClicked = false, 100);
      }
    },
      spawnTaiyoClickEffect() {
        const innerBody = document.querySelector("#bg-color").firstChild;
        const taiyoEl = document.createElement('img');
        taiyoEl.src = require('../assets/images/taiyo_cursor_hover_click.png');
        taiyoEl.style.position = "fixed";
        taiyoEl.style.left = this.xPos + "px";
        taiyoEl.style.top = this.yPos + "px";
        taiyoEl.style.pointerEvents = "none";
        this.taiyoZIndex--;
        taiyoEl.animate([{
          opacity: 50
        },
        {
          transform: 'translateY(-200px)',
          opacity: 0,
        }], 500);
        innerBody.appendChild(taiyoEl);
        setTimeout(() => taiyoEl.remove(), 500);
      }
  },
};
</script>

<style lang="scss" scoped>
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;

body {
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  font-family: 'Russo One', sans-serif;
  text-align: center;
  min-height: 98vh;
  margin: .55rem;
  cursor: url('../assets/images/taiyo_cursor.png'), auto;
  @include colorful-radial-gradient-alt;
}

h1 {
  text-align: center;
}

h3 {
  text-align: center;
  @include text-outline;
}

h3 {
  font-size: 1.25rem;
  @media screen and (max-width: $md) {
    font-size: inherit;
  }
  @include text-outline(4);
}


h5 {
  font-size: 1rem;
  @include text-outline(3px);
  margin-top: 2rem;
}

.welcome {
  font-family: 'abduction2002';  background-color: black;
  margin-top: 1rem;
  font-size: 2rem;
  @media (min-width: $xl) {
    font-size: 3rem;
  }
  @include rainbow-gradient;
}

#taiyo-img {
  @media(min-width: $md) {
    width: 25%;
  }
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  border-width: 3px;
}

#taiyo-img:hover {
  border-color: orange;
}

#enter {
  margin-top: .75rem;
  margin-bottom: 1rem;
  @include text-outline(3px);
}

.taiyo-count {
  font-size: 1.75rem;
  @include text-outline(3px);
}

#back-button {
  font-size: 1.75rem;
  text-align: center;
  @include text-outline(3px);
}

#back-button:hover {
  color: rgb(35, 35, 252);
  cursor: url('../assets/images/taiyo_cursor_hover.png'), auto;
}

.taiyoClicked {
  cursor: url('../assets/images/taiyo_cursor_hover_click.png'), auto;
}

.taiyoHoverNonClicked {
  cursor: url('../assets/images/taiyo_cursor_hover.png'), auto;
}


.footer {
  // left: 45%;
  // bottom: 1px;
  // position: absolute;
  // padding-top: 95%;
  bottom: 10px;
  pointer-events: none;
}

.top-padding {
  margin-top: 5rem;
  // position: absolute;
  // bottom: 30px;
  // right: 45% 
  }

#cursor {
  position: fixed;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  pointer-events: none;
}

.unclicked {
  display: none;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.taiyo-msg-banner {
  font-family: 'abduction2002';
}

</style>
