<template>
  <body ref="bodyBg">
    <div class="container about">
      <div class="row about-info">
        <p>{{ about_info[0].text1 }}<span class="hello-there">hello there!</span></p>
        <p>{{ about_info[0].text2.join(" ") }}</p>
        <p>{{ about_info[0].text3.join(" ") }}</p>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-center">
            <img
              id="me-img"
              class="img-fluid"
              :height="height"
              :src="require(`@/assets/images/${meImgPath}`)"
              @mouseleave="meImgPath='me.jpg'"
              @mouseenter="meImgPath='me-ascii_inverted.png'"
            >
          </div>
        </div>
      </div>
      <h2 class="back-btn">
        <router-link
          id="back-button"
          to="/"
          style="text-decoration: none"
        >
          &#8592; Home
        </router-link>
      </h2>
    </div>
  </body>
</template>

<script>
import aboutInfo from '../data/about_info.json';
import { animateBg } from '../utils/radialZoom.js';

export default {

  data() {
    return {
      height: window.innerHeight / 2,
      about_info: aboutInfo,
      meImgPath: 'me.jpg'
    };
  },
  mounted() {
    animateBg(this.$refs.bodyBg);
  },
  unmounted() {
    animateBg(null, true);
  }
};

</script>

<style lang="scss" scoped>
$xs: 360px;
$sm: 540px;
$md: 768px;
$lg: 960px;
$xl: 1140px;

.hello-there {
  font-style: italic;
}

body {
  margin: .55rem;
  background-color: black;
  min-height: 98vh;
  font-family: 'Russo One', sans-serif;
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  @include colorful-radial-gradient-alt;
}

p {
  font-size: 1rem;
  @media (min-width: $md) {
    font-size: 1.5rem;
  }
  color: rgb(35, 35, 252);
  text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
}

#me-img {
  max-width: 50%;
  margin-top: 2rem;
  @media (min-width: $md) {
    max-width: 25%;
  }
  margin-bottom: 2rem;
  @include hr-outline;
}

.about-info {
  margin-top: 2rem;
  @media(max-width: $sm) {
    padding-left: .5rem;
  }
  border: 5px blue solid;
  background-color: black;
}

#back-button {
  @include text-outline(3px);
}

#back-button:hover {
  color: rgb(35, 35, 252);
}

.back-btn {
  margin-top: 1rem;
  text-align: center;
  text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
}

.back-btn a {
  color: rgb(35, 35, 252);;
}


</style>
