<template>
  <body ref="bodyBg">
    <div class="contact-info-container">
      <h1
        style="border: 5px; border-color: bisque;"
        class="mt-3 contact-header"
      >
        contact me
      </h1>
      <div class="container">
        <div class="row contact-info">
          <h2 class="contact-text">
            email: nathan.mccloud898@gmail.com
          </h2>
        </div>
        <div class="row contact-info">
          <h2 class="contact-text">
            {{ phoneInfo }}
          </h2>
        </div>
      </div>
      <form
        method="POST"
        name="contact"
        netlify
        @submit.prevent="submitUserMessage"
      >
        <input
          type="hidden"
          name="form-name"
          value="contact"
        >
        <div class="msg-area">
          <h2>Or send me a message directly</h2>
          <textarea
            v-model="userText"
            class="contact-form"
            rows="5"
            cols="50"
            name="user-text"
          />
          <h2
            :style="sendMessageResponseStyle"
          >
            {{ sendMessageResponse }}          
          </h2>
        </div>
        <h2>
          <button
            type="submit"
            class="send-button"
          >
            Send
          </button>
        </h2>
      </form>
    </div>
    <div class="home-button">
      <router-link
        id="back-button"
        to="/"
        style="text-decoration: none"
      >
        &#8592; Home
      </router-link>
    </div>
  </body>
</template>

<script>
import { animateBg } from '../utils/radialZoom.js';

export default {
  name: 'Contact',
  data() {
    return {
      userText: "",
      emptyMessageSubmitAttempted: false,
      messageSentSuccessfully: false,
      phoneInfo: 'phone:\n 217-552-9371',
      showRateLimitErrorMessage: false
    }
  },
  computed: {
    sendMessageResponse() {
      if(this.emptyMessageSubmitAttempted)
        return "Please enter a message";
      else if(this.showRateLimitErrorMessage)
        return "Please wait a bit and try again";
      else if(this.messageSentSuccessfully)
        return "Sent!";
      else return "''"; 
    },
     sendMessageResponseStyle() {
      return {
          'visibility': (this.emptyMessageSubmitAttempted || this.messageSentSuccessfully || this.showRateLimitErrorMessage) ? 'visible' : 'hidden',
          'color': (this.emptyMessageSubmitAttempted || this.showRateLimitErrorMessage) ? 'red' : 'lime'
      }
      }
  },mounted() {
    animateBg(this.$refs.bodyBg);
  },
  unmounted() {
    animateBg(null, true);
  },
  methods: {
    async submitUserMessage($event) {
      const form = $event.target;
      const body = new URLSearchParams(new FormData(form));
      console.log(body);
      if(this.userText.trim().length === 0)
      {
        this.emptyMessageSubmitAttempted = true;
      }  
      else {
        try {
          const res = await fetch(form.action, { method: 'POST', body});
          if(res.status === 200) {
            this.messageSentSuccessfully = true;
            this.userText = "";
          }
          if(res.status === 429) {
            this.showRateLimitErrorMessage = true;
          }
          } catch(err) {console.log(err)
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$xs: 490px;
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;

body {
  border-color: rgb(35, 35, 252);    border-style: dotted;
  border-spacing: 1em;
  font-family: 'Russo One', sans-serif;
  background-color: black;
  text-align: center;
  min-height: 98vh;
  margin: .55rem;
  @include colorful-radial-gradient-alt;
}

.contact-info {
  word-break: break-all;
}

.contact-form {
  max-width: 95%;
  resize: both;
  font-size: 2rem;
}

.msg-area {
  margin-top: 2.5rem;
}

h1, h3 {
  text-align: center;
  @include text-outline(.3rem);
}

h2 {
  @include text-outline(3px);
}

h3 {
  font-size: 1.75rem;
  @media screen and (max-width: $md) {
    font-size: inherit;
  }
}

h5 {
  font-size: 1rem;
  color: rgb(35, 35, 252);
  text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
  margin-top: 6rem;
}

.contact-header {
  @include text-outline(4px);
  @include rainbow-gradient;
  font-family: 'abduction2002';
}

.break {
  height: .5em;
  width: 90%;
  @include hr-outline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#enter {
  margin-top: 1.25rem;
}

#back-button {
  font-size: 1.75rem;
  @include text-outline(3px);
}

#back-button:hover {
  color: rgb(35, 35, 252);
}

ul {
  list-style-type: none;
}

li > h2 {
  text-align: left;
}

.send-button {
  color: rgb(35, 35, 252);
  border-color: rgb(35, 35, 252);
  box-shadow: 0px 2px rgba(190, 36, 190, 0.5);
  background-color: black;
  margin-bottom: 1.5rem;
}

.send-button:hover {
  filter: brightness(60%);
}

.home-button {
  margin-top: 7rem;
}

.contact-text {
  @media (max-width: $md) {
    font-size: 1.5rem; 
  }
  @media (max-width: $xs) {
    font-size: 1rem;
    margin-left: 1rem;
  }
}

.contact-info-container {
  background-color: black;
  width: 90%;
  margin-left: 5%;
  margin-top: .5rem;
  border: 1px dashed white;
}

</style>
