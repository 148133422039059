<!-- //TODO: Add short stories (the seed), short film screenplay, poems maybe (...?), ImageInverter and ytDownloader -->
<template>
  <body ref="bodyBg">
    <div class="container about">
      <h1>Coming Soon</h1>
    </div>
  </body>
</template>
  
  <script>
  import { animateBg } from '../utils/radialZoom.js';

  export default {
    data() {
      return {
        height: window.innerHeight / 2,
      };
    },
    mounted() {
    animateBg(this.$refs.bodyBg);
  },
  unmounted() {
    animateBg(null, true);
  }
  };
  
  </script>
  
  <style lang="scss" scoped>
  $xs: 360px;
  $sm: 540px;
  $md: 768px;
  $lg: 960px;
  $xl: 1140px;
  
  
  h1 {
    font-size: 1rem;
    @media (min-width: $md) {
      font-size: 1.5rem;
    }
    color: rgb(35, 35, 252);
    text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
  }
  
  #me-img {
    max-width: 50%;
    margin-top: 2rem;
    @media (min-width: $md) {
      max-width: 25%;
    }
    margin-bottom: 2rem;
    @include hr-outline;
  }
  
  .about-info {
    margin-top: 2rem;
    @media(max-width: $sm) {
      padding-left: .5rem;
    }
  }
  
  #back-button {
    @include text-outline(3px);
  }
  
  #back-button:hover {
    color: rgb(35, 35, 252);
  }
  
  .back-btn {
    margin-top: 1rem;
    text-align: center;
    text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
  }
  
  .back-btn a {
    color: rgb(35, 35, 252);;
  }
  
  
  </style>
  