<template>
  <body ref="bodyBg">
    <button
      class="show-more-btn"
      style="margin-top: .5rem;"
      @click="toggleShowMore()"
    >
      {{ showMoreText }} 
    </button>
    <p
      v-show="showMoreInfo"
      class="more-info"
    >
      {{ video_info.text }}
    </p>
    <div
      class="container-fluid"
      style="margin-top: 1rem;"
    >
      <div class="row">
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/RYR9TlCXIaA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/M2SdNY_0DXY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/xxev66v7Im4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/EZap0iw-ZUo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/qeO7T88pNZY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/MWtxR5luo0E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/KCB9hupA_qI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="embed-responsive embed-responsive-16by9 video">
            <iframe
              src="https://www.youtube.com/embed/tuxPLFtyGb0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
    <h2>
      <router-link
        id="back-button"
        to="/"
        style="text-decoration: none"
      >
        &#8592; Home
      </router-link>
    </h2>
  </body>
</template>

<script>
import videoInfo from '../data/video_info.json';
import { animateBg } from '../utils/radialZoom.js';

export default {
  name: 'Videos',
  data() {
    return {
      showMoreInfo: false,
      video_info: videoInfo,
      showMoreText: 'show more',
    }
  },mounted() {
    animateBg(this.$refs.bodyBg);
  }, unmounted() {
    animateBg(null, true);
  },
  methods: {
    toggleShowMore() {
      this.showMoreText = this.showMoreInfo ? 'show more' : 'show less';
      this.showMoreInfo = !this.showMoreInfo;
    }
  }
};
</script>

<style lang="scss" scoped>
/* TODO: animated borders */

$iframe-width: 95vh;
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;

body {
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  font-family: 'Russo One', sans-serif;
  background-color: black;
  text-align: center;
  margin: .55rem;
  @include colorful-radial-gradient-alt;
}

h1, h2, h3, a {
  @include text-outline(3px);
  text-align: center;
}

p {
  color: rgb(35, 35, 252);
  margin: 1rem .5rem 0px 1rem;
}

.show-more-btn {
  color: rgb(35, 35, 252);
  border-color: rgb(35, 35, 252);
  box-shadow: 0px 2px rgba(190, 36, 190, 0.5);
  background-color: black;
}

.show-more-btn:hover {
  filter: brightness(60%);
}

#videos {
  margin: auto;
}

/* do not change!!! */
.video {
  border-color: rgb(35, 35, 252);
  border-width: 1.5px;
  border-style: solid;
  margin-bottom: 1rem;
}

.video:hover {
  border-color: orange;
}

#back-button:hover {
  color: rgb(35, 35, 252);
}

.main-header {
  @include text-outline(4.5px);
}

.more-info {
  background-color: black;
  border: 3px blue solid;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

</style>