import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Videos from '../views/Videos.vue';
import Games from '../views/Games.vue';
import Taiyo from '../views/Taiyo.vue';
import Contact from '../views/Contact.vue';
import Misc from '../views/Misc.vue';

const routes = [
  {
    path: '/',
    name: 'Mathan NcCloud - Main',
    component: Home,
  },
  {
    path: '/about',
    name: 'Mathan NcCloud - About',
    component: About,
  },
  {
    path: '/videos',
    name: 'Mathan NcCloud - Videos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Videos,
  },
  {
    path: '/taiyo',
    name: 'Taiyo',
    component: Taiyo,
  },
  {
    path: '/games',
    name: 'Mathan NcCloud - Games',
    component: Games,
  },
  {
    path: '/contact',
    name: 'Mathan NcCloud - Contact',
    component: Contact
  },
  {
    path: '/misc',
    name: 'Mathan NcCloud - Miscellaneous',
    component: Misc,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
