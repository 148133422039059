<template>
  <body ref="bodyBg">
    <div style="width:90%; background-color: black; margin-left: 5%; margin-top: .5rem; border: 1px dashed white;">
      <h1
        class="
    welcome"
      >
        welcome to mathan nccloud
      </h1>
      <h3 id="enter">
        <!-- enter at your peril . . . -->
        {{ messages[messageIndex] }}
      </h3>
      <hr class="break">
      <div
        class="container"
      >
        <div class="row">
          <div class="mx-auto">
            <link-component class="link-comp" />
          </div>
        </div>
        <div class="mx-auto">
          <img
            id="taiyo-img"
            :src="require(`@/assets/${taiyosLocation}`)"
            @mouseover="taiyosLocation='gifs/taiyo3s.gif'"
            @mouseleave="taiyosLocation='gifs/tasiyosresized/tq.png'"
            @click="goToTaiyo"
          >
        </div>
      </div>
      <footer>
        <h5 id="trademark">
          Nathan McCloud 2024
        </h5>
      </footer>
    </div>
  </body>
</template>

<script setup>
import LinkComponent from '../components/LinkComponent.vue';
import { animateBg } from '../utils/radialZoom.js';
import { messages } from '../data/banner_msgs.js';
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted, ref } from 'vue';

const router = useRouter();
const taiyosLocation = ref('gifs/tasiyosresized/tq.png');
const messageIndex = ref(0);
const bodyBg = ref(null);

const  goToTaiyo = () => {
  router.push('/taiyo');
}

onMounted(() => {
  messageIndex.value = Math.floor(Math.random() * messages.length);
  animateBg(bodyBg.value);
})

onUnmounted(() => {
  animateBg(null, true);
})


</script>

<style lang="scss" scoped>

$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;

body {
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  // font-family: 'Russo One', sans-serif;
  font-family: 'abduction2002';
  background-color: black;
  text-align: center;
  min-height: 100vh;
  margin: .55rem;
  color: white;
  @include colorful-radial-gradient-alt;
}

h1 {
  text-align: center;
}

h3 {
  text-align: center;
  @include rainbow-gradient;
  @include text-outline;
}

h3 {
  font-size: 1.5rem;
  @media screen and (max-width: $md) {
    font-size: inherit;
  }
}

.link-comp {
  @media(min-width: $xl) {
    margin-top: 1rem;
  }
}

h2 {
  font-size: 2.5rem;
}

h5 {
  font-size: 1rem;
  @include text-outline(3px);
}

.welcome {
  grid-column: 1;
  grid-row: 1;
  font-size: 2rem;
  @media (min-width: $xl) {
    font-size: 3rem;
    margin-top: 1rem;
  }
  @include rainbow-gradient;
}
.break {
  height: .5em;
  width: 70%;
  @include rainbow-gradient;
  @include hr-outline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

#taiyo-img {
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  border-width: 2.5px;
  max-width: 50%;
  @media (min-width: $md) {
    max-width: 35%;
  }
  // @media (min-width: $lg) {
  //   max-width: 25%;
  // }
}

#taiyo-img:hover {
  border-color: orange;
  cursor: pointer;
}

#enter {
  margin-top: 1.25rem;
  @include text-outline(3px);
}

#trademark {
  margin-top: 5rem;
  font-family: 'Russo One', sans-serif;
}

</style>
