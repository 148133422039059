<template>
  <body ref="bodyBg">
    <div class="container games-container">
      <div class="row">
        <div v-if="windowSizeBelowThreshold && !usingMobile">
          <h3 class="screen-size-under-threshold">
            Please make sure your window size is at least 1200 x 700 pixels 
          </h3>
        </div>
        <div
          v-if="usingMobile"
          class="mobile-game-placeholder"
        >
          <p style="font-size: 2rem;">
            <!-- Mobile-Compatible games: -->
            Mobile-Compatible games (coming soon)
          </p>
          <img
            style="width: 250px"
            src="../assets/images/noun-work-in-progress-215094.png"
          >
          <!-- <button @click="() => { toggleShowGame('/games/Cubulus/WebGL/index.html'); }">
            <p style="font-size: 2rem;">
              CUBULUS
            </p>
          </button> -->
        </div>
        <ul
          v-if="!windowSizeBelowThreshold"
          class="nav nav-pills"
        >
          <li class="nav-item">
            <button
              class="game-btn"
              style="margin-right: 1rem;"
              @mouseover="toggleShowInfo(0, 'CubulusIcon.png')"
              @mouseleave="showInfo = false"
              @click="toggleShowGame('/games/Cubulus/WebGL/index.html')"
            >
              <span
                class="game-title"
              > CUBULUS </span>
            </button>
          </li>
          <li
            class="
                nav-item"
          >
            <button
              class="game-btn"
              @mouseover="toggleShowInfo(1, 'robot3_blaster1_akimbo.png')"
              @mouseleave="showInfo = false"
              @click="toggleShowGame('/games/RogueLikeTest/WebGL/index.html')"
            >
              <span
                class="game-title"
              > 
                Untitled 2D Shooter
              </span>
            </button>
          </li>
        </ul>
        <iframe
          v-if="(showGame && !windowSizeBelowThreshold)"
          class="game"
          :src="gameSRC"
          title="GameWindow"
          :style="usingMobile ? 'transform: rotate(90deg);' : ''"
        />
        <div
          v-if="!showGame && !windowSizeBelowThreshold"
          id="game-placeholder"
        >
          <div id="inner-placeholder">
            <h2
              v-if="!showInfo"
              style="padding-top: 3rem;"
            >
              Select a Game to Play
            </h2>
            <img
              v-if="!showInfo"
              id="controller-img"
              src="../assets/images/controller.svg"
            >
            <img
              v-if="showInfo"
              id="game-icon"
              :src="require(`@/assets/images/${imgPath}`)"
            >
            <div v-if="showInfo">
              <h3 style="padding-top: 3rem; padding-bottom: 1rem;">
                {{ game_Info[gameIndex].Name }}
              </h3>
              <h4 style="text-align: left; margin-left: 1rem;">
                How to play: <br>{{ game_Info[gameIndex].HowToPlay }}
              </h4>
              <br>
              <h4 style="text-align: left; margin-left: 1rem;">
                Controls:
                <div
                  v-for="control in gameControls"
                  :key="control.id"
                >
                  {{ control }}
                </div>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h2 class="back-btn">
      <router-link
        id="back-button"
        to="/"
        style="text-decoration: none"
      >
        &#8592; Home
      </router-link>
    </h2>
  </body>
</template>

<script>
import gameInfo from '../data/game_info.json';
import { animateBg } from '../utils/radialZoom.js';

export default {
  name: 'Games',
  data() {
    return {
      showGame: false,
      showInfo: false,
      gameSRC: '',
      game_Info: gameInfo,
      game_Index: 0,
      img_Path: 'CubulusIcon.png',
      usingMobile: false,
      windowSizeBelowThreshold: false,
      MIN_HEIGHT: 700,
      MIN_WIDTH: 1200,
      iframeRotated: false
    };
  },
  computed: {
    gameIndex() {
      return this.game_Index;
    },
    gameControls() {
      return gameInfo[this.game_Index].Controls;
    },
    imgPath() {
      return this.img_Path;
    },
  },
  created() {
    this.windowSizeBelowThreshold = window.innerWidth < this.MIN_WIDTH || window.innerHeight < this.MIN_HEIGHT ;
  },
  mounted() {
    animateBg(this.$refs.bodyBg);
    window.addEventListener('resize', () => { 
      console.log('resizing'); this.windowSizeBelowThreshold = window.innerWidth  < this.MIN_WIDTH || window.innerHeight < this.MIN_HEIGHT;
    });
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator)
      hasTouchScreen = navigator.maxTouchPoints > 0;
    else if ("msMaxTouchPoints" in navigator) 
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
     else {
      const mQ = matchMedia?.("(pointer:coarse)");
      if (mQ?.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ("orientation" in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        hasTouchScreen =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (hasTouchScreen) {
      this.usingMobile = true;
    }
  },
  unmounted() {
    animateBg(null, true);
  },
  methods: {
    toggleShowGame(gameSrc) {
      this.showGame = !this.showGame;
      if (this.showGame) this.gameSRC = gameSrc;
    },
    toggleShowInfo(index, imgPath) {
      this.game_Index = index;
      this.img_Path = imgPath;
      this.showInfo = true;
    },
  }
};
</script>

<style lang="scss" scoped>
  $xs: 0px;
  $sm: 576px;
  $md: 768px;
  $lg: 992px;
  $xl: 1200px;

body {
  border-color: rgb(35, 35, 252);
  border-style: dotted;
  border-spacing: 1em;
  font-family: 'Russo One', sans-serif;
  background-color: black;
  text-align: center;
  min-height: 98vh;
  margin: .55rem;
  @include colorful-radial-gradient-alt;
}

h1, h3, p {
  color: rgb(35, 35, 252);
  text-align: center;
  text-shadow: 0px 3px rgba(190, 36, 190, 0.5);
}

iframe {
  border-color: rgb(35, 35, 252);
  border-width: 5px;
  border-style: solid;
  // width: 800px;
  height: 640px;
  // height: 350px;
  width: 1100px;
}

iframe:hover {
    border-color: orange;
}

.game-title {
  color: rgb(190, 36, 190);
}

#game-placeholder {
  border-color: rgb(35, 35, 252);
  border-width: 3px;
  border-style: dotted;
  width: 1100px;
  height: 640px;
  background-color: black;
}

#game-placeholder:hover {
  border-color: orange;
}

#inner-placeholder {
  width: 1000px;
  height: 560px;
  background-color: grey;
  position: relative;
  top: 5.45%;
  left: 4%;
}

#controller-img {
  position: absolute;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
}

#game-icon {
  position: absolute;
  top: 50%;
  left: 65%;
  height: 25%;
  width: 15%;
  border-radius: 1px;
  border-style: dashed;
  border-color: rgb(35, 35, 252);
}

.main-header {
  @include text-outline(4px);
  margin-top: 2rem;
}

.game-btn {
  @include hr-outline;
}

.game-btn:hover {
  border: 2px solid lightblue;
  filter: brightness(60%);
}

#back-button:hover {
  color: rgb(35, 35, 252);
}

.back-btn {
  margin-top: 1rem;
  text-align: center;
}

.back-btn a {
  @include text-outline(3px);
}

.screen-size-under-threshold {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: .25rem;
  background-color: black;
  border: 1px blue solid;
  @media (min-width: $lg) {
    margin-left: 11rem;
  }
}

.mobile-game-placeholder {
  background-color: black;
}

.games-container {
  margin-top: 5rem;
}

/* TODO: Add Bounce Master, add links to itch.io/google play/steam(maybe ?) "Also available at..." */ 
</style>
