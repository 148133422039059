<template>
  <div>
    <router-link
      to="/about"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          about
        </a>
      </h2>
    </router-link>
    <!-- <router-link
      to="/software"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          software</a>
      </h2>
    </router-link> -->
    <router-link
      to="/videos"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          video art</a>
      </h2>
    </router-link>
    <router-link
      to="/games"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          game projects</a>
      </h2>
    </router-link>
    <!-- <router-link
      to="/misc"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          miscellaneous</a>
      </h2>
    </router-link> -->
    <router-link
      to="/contact"
      style="text-decoration: none;"
    >
      <h2>
        <a href="">
          contact</a>
      </h2>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'LinkComponent',
};

</script>

<style lang="scss" scoped>
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;

h2 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  @media (max-width: $md) {
    font-size: 1.5rem;    
  }
}

h2 a {
  @include rainbow-gradient;
  @include text-outline(3px);
  text-decoration: none;
}

router-link a {
  text-decoration: none;
}

h2 a:hover {
  filter: brightness(60%);
  // color: rgb(35, 35, 252);
}


</style>
