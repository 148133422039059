import { ref } from 'vue'
const offSet = ref(0);
const animId = ref(0);

export function animateBg(inputRef, cancelAnim = false) {
  const animate = () => {
    offSet.value+=.5;
    if(offSet.value > 100) offSet.value = 0;
    setOffset(offSet.value);
    if(!cancelAnim) animId.value = requestAnimationFrame(animate)
    else cancelAnimationFrame(animId.value);
  } 

  const setOffset = (value) => {
    inputRef?.style.setProperty("--offset", value + "px");
  }

  animate();
}