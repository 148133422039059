<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: abduction2002;
  src: url('../src/assets/fonts/fonts/abduction2002.ttf');
}

</style>
